// UserDashboard.js
import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import CheckoutButton from './CheckoutButton';

const UserDashboard = () => {
    const [subscriptionStatus, setSubscriptionStatus] = useState('Free');
    const [tokenCount, setTokenCount] = useState(5); // Default token count for free users
    const [showCongrats, setShowCongrats] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const db = getDatabase();
            const userRef = ref(db, 'users/' + user.uid);

            onValue(userRef, (snapshot) => {
                const data = snapshot.val();

                if (data) {
                    setSubscriptionStatus(data.subscriptionStatus || 'Free');
                    setTokenCount(data.tokenCount || 5);

                    if (data.subscriptionStatus === 'Premium') {
                        setShowCongrats(true);
                    } else {
                        setShowCongrats(false);
                    }
                }
            });
        }
    }, []);

    return (
        <div style={{ color: "#a0a0a0", fontSize: "xx-large", padding: '1em' }}>
            <h1>User Dashboard</h1>
            <p>Subscription Status: {subscriptionStatus}</p>
            <p>Token Count: {tokenCount}</p>
            {showCongrats && <p>Congratulations on upgrading to Premium!</p>}
            <CheckoutButton priceId={'price_1PxbK0KwYi8l6iBvrAFic2Oc'} />
        </div>
    );
};

export default UserDashboard;
