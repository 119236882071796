// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './Chat'
import Analytics from './Analytics';
import UserDashboard from './UserDashboard';
import CheckoutButton from './CheckoutButton';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Chat />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/test" element={<>
                    <UserDashboard />
                </>} />
            </Routes>
        </Router>
    );
}

export default App;
