import axios from 'axios'
import { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts'

const monthlyData = [
    { name: 'Jan', users: 4000 },
    { name: 'Feb', users: 3000 },
    { name: 'Mar', users: 2000 },
    { name: 'Apr', users: 2780 },
    { name: 'May', users: 1890 },
    { name: 'Jun', users: 2390 },
]

const userTypeData = [
    { name: 'Paying Users', value: 400 },
    { name: 'Free Users', value: 600 },
]

const topicTrendsData = [
    { name: 'Business', value: 400 },
    { name: 'Technology', value: 300 },
    { name: 'Finance', value: 200 },
    { name: 'Marketing', value: 150 },
    { name: 'Leadership', value: 100 },
]

const satisfactionData = [
    { time: '9AM', score: 4.2 },
    { time: '12PM', score: 4.5 },
    { time: '3PM', score: 4.0 },
    { time: '6PM', score: 4.3 },
    { time: '9PM', score: 4.7 },
]

const COLORS = ['#58009f', '#ADADAD', '#8884d8', '#82ca9d', '#ffc658']

export default function EnhancedAnalyticsDashboard() {
    const [activeTab, setActiveTab] = useState('Overview')
    const [closed, setClosed] = useState(false)
    const [totalUsers, setTotalUsers] = useState(12345)

    useEffect(() => {
        axios.get("http://127.0.0.1:7000/api/analytics_overview")
            .then(r => {
                setTotalUsers(r.data.users)
            })
    }, [])

    const renderContent = () => {
        switch (activeTab) {
            case 'Overview':
                return (
                    <div style={{ display: "flex", flexDirection: "column", gap: '20px' }}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8" style={{
                            display: 'flex',
                            // flexDirection: "column",
                            width: "100%",
                            gap: "20px"
                        }}>
                            {/* Monthly Users Chart */}
                            <div className="card bg-[#151618] p-4 rounded-lg" style={{
                                paddingRight: "20px",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                                <h3 className="text-xl font-semibold mb-4" style={{
                                    fontWeight: 700
                                }}>Monthly Users</h3>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={monthlyData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="users" fill="#58009f" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>

                            {/* User Type Distribution */}
                            <div className="bg-[#151618] p-4 rounded-lg card" style={{
                                paddingRight: "20px",
                                width: "100%"
                            }}>
                                <h3 className="text-xl font-semibold mb-4">User Type Distribution</h3>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie
                                            data={userTypeData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {userTypeData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className="flex justify-center mt-4" style={{
                                    display: "flex",
                                    justifyContent: "space-evenly"
                                }}>
                                    {userTypeData.map((entry, index) => (
                                        <div key={`legend-${index}`} className="flex items-center mx-2" style={{
                                            display: "flex",
                                            gap: ".6em",
                                            alignItems: "center"
                                        }}>
                                            <div className="w-3 h-3 mr-1" style={{
                                                backgroundColor: COLORS[index % COLORS.length],
                                                width: "20px", height: "20px"
                                            }}></div>
                                            <span>{entry.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Key Metrics */}
                        <div className="bg-[#151618] p-4 rounded-lg mt-8 card" style={{
                            display: "flex",
                            gap: "10px"
                        }}>
                            <h3 className="text-xl font-semibold mb-4">Key Metrics</h3>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4" style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingRight: "23px"
                            }}>
                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Avg. Messages/User</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>23.5</p>
                                </div>

                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Avg. Sessions/User</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>3.4</p>
                                </div>
                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Avg. Session Time</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>12m 30s</p>
                                </div>
                                <div>
                                    <p className="text-[#ADADAD]" style={{
                                        color: "#adadad"
                                    }}>Conversion Rate</p>
                                    <p className="text-2xl font-bold" style={{
                                        fontSize: "xx-large",
                                        fontWeight: "600"
                                    }}>8.7%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'Query Analysis':
                return (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em"
                    }}>
                        {/* Topic Trends */}
                        <div className="bg-[#151618] p-4 rounded-lg mb-8 card">
                            <h3 className="text-xl font-semibold mb-4">Topic Trends</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={topicTrendsData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="#58009f" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        {/* Top Queries and Dissatisfaction Triggers */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8" style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1em"
                        }}>
                            <div className="bg-[#151618] p-4 rounded-lg card" style={{
                                width: "100%",
                                gap: "1em",
                            }}>
                                <h3 className="text-xl font-semibold mb-4" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: '10px',
                                    color: "#9AE19D"
                                }}>
                                    <img src='gup.svg' width={20} />
                                    Top Queries
                                </h3>
                                <ul className="space-y-2 queries" style={{
                                }}>
                                    <li>1. How to start a business?</li>
                                    <li>2. Tips for effective networking</li>
                                    <li>3. Best investment strategies</li>
                                    <li>4. How to scale a startup</li>
                                    <li>5. Negotiation techniques</li>
                                </ul>
                            </div>
                            <div className="bg-[#151618] p-4 rounded-lg card" style={{
                                width: "100%",
                                gap: "1em",
                            }}>
                                <h3 className="text-xl font-semibold mb-4" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: '10px',
                                    color: "#DB5461"
                                }}>
                                    <img src='gdown.svg' width={20} />
                                    Common Dissatisfaction Triggers
                                </h3>
                                <ul className="space-y-2 queries">
                                    <li>1. Lack of specific industry examples</li>
                                    <li>2. Outdated financial advice</li>
                                    <li>3. Vague responses to technical questions</li>
                                    <li>4. Inconsistent advice on legal matters</li>
                                    <li>5. Limited personalization in responses</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            case 'User Satisfaction':
                return (
                    <>
                        {/* Satisfaction Score Over Time */}
                        <div className="bg-[#151618] p-4 rounded-lg mb-8">
                            <h3 className="text-xl font-semibold mb-4">Satisfaction Score Over Time</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={satisfactionData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="time" />
                                    <YAxis domain={[0, 5]} />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="score" stroke="#58009f" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>

                        {/* Most Satisfactory Responses */}
                        <div className="bg-[#151618] p-4 rounded-lg mb-8">
                            <h3 className="text-xl font-semibold mb-4">Most Satisfactory Responses</h3>
                            <ul className="space-y-2">
                                <li>1. Detailed step-by-step guides on business processes</li>
                                <li>2. Personalized advice based on user's industry and experience</li>
                                <li>3. Up-to-date market trends and analysis</li>
                                <li>4. Practical examples of successful business strategies</li>
                                <li>5. Clear explanations of complex financial concepts</li>
                            </ul>
                        </div>
                    </>
                )
            default:
                return null
        }
    }

    useEffect(() => {
        if (window.innerWidth < 1300) setClosed(true)
    }, [])

    const user = null;

    return (<>
        <div className="header">
            <div className="logo"> <img src="logo.png" alt="logo"
                height='55px' className="imgLogo" /> AlphaTwin</div>
            <div className="welcome">Welcome <span className="username">{
                user?.displayName ?
                    user.displayName :
                    "New User"
            }</span> 👋</div>
            <div className='socials'>
                <a className='subscribe' href='https://buy.stripe.com/test_8wM8y3eMccfEghi8ww'>
                    Subscribe
                </a>
                <div style={{ position: "relative" }}>
                    <img src={
                        (user && user.photoURL) ?
                            user.photoURL : 'user.svg'
                    } className='user' width='40'
                    // onClick={() => setShow(s => !s)} 
                    />
                    {/* <div ref={profileRef} className={`dropdown ${show ? "show" : "noShow"}`}>
                        {user ?
                            <>
                                <div className="dropdownItem">
                                    <div className='notSignedIn'>{user.displayName}</div>
                                </div>

                                <div className="dropdownItem" onClick={() => {
                                    signOut(auth)
                                        .then(() => {
                                            // Sign-out successful.
                                            console.log("User signed out successfully");
                                            setUser(null)
                                        })
                                        .catch((error) => {
                                            // Handle error during sign-out.
                                            console.error("Error signing out: ", error);
                                        });
                                }}>
                                    <img src="logout.svg" width={'18'} />
                                    <span>
                                        Logout
                                    </span>

                                </div>
                            </> : <>
                                <div className="dropdownItem">
                                    <div className='notSignedIn'>Not Signed In</div>
                                </div>
                                <div className="dropdownItem" onClick={signInWithGoogle}>
                                    <img src="google.svg" width={'18'} />
                                    <span>
                                        Google Login
                                    </span>
                                </div>
                                <div className="dropdownItem">
                                    <img src="info.svg" width={'18'} />
                                    <a href='https://alphatwin.ai' style={{ all: 'unset' }}>
                                        Learn More
                                    </a>
                                </div>
                            </>
                        }
                    </div> */}
                </div>
            </div>

        </div>
        <div className="body flex h-screen bg-[#0A0A0A] text-white" style={{
            display: "flex",
            background: "#0A0A0A",
            color: "white",
            fontFamily: "",
            // height: "100svh",
            gap: "1em",
            fontSize: "16px",
            fontFamily: "Open Sans",
            // fontWeight: 700,
            paddingBottom: "60px"
        }}>
            {/* Left Sidebar */}
            <div className={`leftBar ${!closed && "closedOff"} w-1/4 p-4 bg-[#151618] space-y-4`} style={{

            }}>
                <div className="card" style={{ height: "100%" }}>
                    <h2 className="text-2xl font-bold mb-4">Analytics Menu</h2>
                    <div className="space-y-2 menu">
                        {['Overview', 'Query Analysis', 'User Satisfaction'].map((tab) => (
                            <button
                                key={tab}
                                className={`${tab === activeTab && "selected"} w-full text-left p-2 menuButton hoverable rounded ${activeTab === tab ? 'bg-[#58009f]' : 'hover:bg-[#58009f]'}`}
                                onClick={() => setActiveTab(tab)}
                                style={{ width: "90%" }}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className={`middleBar ${!closed && "closedOff"}`}>
                {/* <h1 className="text-3xl font-bold mb-8">Analytics Dashboard</h1> */}
                {renderContent()}
            </div>

            {/* Right Sidebar */}
            <div className={`w-1/4 p-4 bg-[#151618] space-y-4 rightBar ${closed && "closed"}`} style={{
                // width: "max-content"
            }}>
                <div className="card" style={{
                    gap: "1em",
                    height: "100%"
                }}>

                    <h2 className="text-2xl font-bold mb-4">Quick Stats</h2>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>Total Users</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>{totalUsers}</p>
                    </div>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>New Users (This Month)</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>+1,234</p>
                    </div>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>Active Subscribers</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>3,456</p>
                    </div>

                    <div className="bg-[#0A0A0A] p-4 rounded-lg" style={{
                        background: "#0A0A0A",
                        marginLeft: ".5em",
                        marginRight: "1em",
                        paddingInline: "1em",
                        paddingBlock: ".5em",
                        borderRadius: "8px"
                    }}>
                        <p className="text-[#ADADAD]" style={{
                            color: "#ADADAD"
                        }}>Revenue (This Month)</p>
                        <p className="text-3xl font-bold" style={{
                            fontSize: "xx-large",
                            fontWeight: 600
                        }}>$67,890</p>
                    </div>

                </div>
            </div>
        </div>
    </>
    )
}