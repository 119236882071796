import React, { useState, useEffect } from 'react';
import { firestore } from './firebaseConfig'; // Adjust the import path to your Firebase config
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    onSnapshot,
} from 'firebase/firestore';
import { motion } from 'framer-motion'
import { onAuthStateChanged } from 'firebase/auth';
// import { auth } from './firebaseConfig';

const ChatLog = ({ sessionID, messages, setMessages, user, newID }) => {

    useEffect(() => {
        if (!sessionID || !user || !user?.uid || newID) return;
        const messagesRef = collection(
            firestore,
            `chatSessions/${sessionID}/messages`
        );

        const q = query(messagesRef, orderBy('timestamp'));

        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
                const msgs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessages(msgs);
            },
            (error) => {
                console.error('Error fetching messages:', error);
                console.log(sessionID, user?.uid)
            }
        );

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [sessionID, user, newID]);

    return (
        <div className="chat-log">
            {messages.map((m, i) => (
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, transition: { duration: 0.15 } }}
                    className={`message ${m.user === 1 ? "sending" : "receiving"}`}
                    style={{
                        marginBottom: (
                            (i < messages.length - 1) && (m.user === messages[i + 1].user)
                        ) && ".4em",
                        // paddingBottom: (m.showTime && m.user === 0) && "1.4em",
                    }}>
                    <>
                        {m.content.split("**").map((m, idx) => <>
                            {
                                idx % 2 === 0 ? <>{m}</> : <b>{m}</b>
                            }
                        </>)}
                    </>
                </motion.div>
            ))}
        </div>
    );
};

export default ChatLog;
