import React, { useEffect, useState } from 'react'

import { auth, firestore } from './firebaseConfig'; // Adjust the import path to your Firebase config
import {
    collection,
    query,
    where,
    orderBy,
    getDocs,
    onSnapshot,
} from 'firebase/firestore';

const pics = {
    'alex': `https://www.acquisition.com/hs-fs/hubfs/alexphoto-1.jpg?width=400&height=400&name=alexphoto-1.jpg`,
    'aiguy': `https://yt3.googleusercontent.com/sETg5-Gg1Eg86vAj3Gocigb-14nlNXb3J5qCkh4wvxc3WWiY-Myx-ENfN4G-JYyiPUFajWyFqPg=s160-c-k-c0x00ffffff-no-rj`
}

const names = {
    'alex': `Alex Hormozi`,
    'aiguy': `AI Guy`
}

export default function History({ setNewID, sessionID, setSessionID, user, influencerID }) {

    const [chats, setChats] = useState([])
    const currentUserId = user?.uid

    useEffect(() => {
        if (!user) {
            setChats([])
            return;
        };
        const chatSessionsRef = collection(firestore, 'chatSessions');
        if (!currentUserId) {
            setChats([]);
            return;
        }
        const q = query(chatSessionsRef, where('userID', '==', currentUserId), where('influencerID', '==', influencerID), orderBy("lastMessageTimestamp", "desc"));

        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
                const c = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setChats(c);
            },
            (error) => {
                console.error('Error fetching messages:', error);
            }
        );

        return () => unsubscribe();

    }, [auth, user, influencerID])

    return (
        <div className='histContainer'>
            {chats.map(c =>
                <div key={c.id} className={`profile hoverable ${sessionID === c.id && "selected"}`} onClick={() => {
                    setSessionID(c.id)
                    setNewID(false)
                }}>
                    <img src={pics[c.influencerID]} alt="" />
                    <div className="desc">
                        <div className="name">{names[c.influencerID]}</div>
                        <div className="bio">{c.title}</div>
                    </div>
                </div>
            )}
        </div>
    )
}
